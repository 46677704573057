import { useEffect, useState } from 'react';
import { getLanguageFromField } from '../utilities/sharedUtils';
import { TS } from '../utilities/urlConstants';

const urljoin = require('url-join');

const useOnNewActiveLanguage = (
  actualLocale,
  translations = null,
  middleSlug = ''
) => {
  const [newActiveLanguage, setNewActiveLanguage] = useState('');
  const noredir = '?noredir=1';

  useEffect(() => {
    if (!newActiveLanguage) return;

    if (actualLocale === newActiveLanguage) return;

    const expectedLanguageObj = getLanguageFromField(
      'locale',
      newActiveLanguage
    );

    if (!translations) {
      // No translations - navigate to homepage
      if (expectedLanguageObj?.languageSiteUrl) {
        window.location.replace(
          urljoin(expectedLanguageObj?.languageSiteUrl, TS, noredir)
        );
        return;
      }
    }

    // Find translation for item
    const existingTranslation = translations?.find(
      (translation) => translation?.language?.locale === newActiveLanguage
    );

    if (!existingTranslation && expectedLanguageObj?.languageSiteUrl) {
      // If no translation for item - go to homepage
      window.location.replace(
        urljoin(expectedLanguageObj?.languageSiteUrl, TS, noredir)
      );
      return;
    }

    // If translation exists - go to respective item page
    window.location.replace(
      urljoin(
        expectedLanguageObj?.languageSiteUrl,
        middleSlug,
        existingTranslation?.slug,
        TS,
        noredir
      )
    );
  }, [newActiveLanguage]);

  return setNewActiveLanguage;
};

export default useOnNewActiveLanguage;
